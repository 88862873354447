.container {
  width: 660px;
  margin: 0 auto;
}

button {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  margin-top: 24px;
  margin-bottom: 24px;
}

button:not(:last-child) {
  margin-right: 10px;
}
